import React, { Component, createContext } from 'react';
import { auth, generateUserDocument } from '../config/firebase-config';
import { useState, useEffect } from 'react';
//import {Redirect} from 'react-router-dom';

export const UserContext = createContext({ user: null });

  

 const UserProvider = ( {children} ) => {

     const [user, setUser] = useState(null);
  
     useEffect (() => {
        auth.onAuthStateChanged(async userAuth => {
            const user = await generateUserDocument(userAuth);
             //const user = await userAuth;
            setUser(user);
          });

     }, []);

 
    return (
      <UserContext.Provider value={{user, setUser}}>

        {children}
        
      </UserContext.Provider>
    );
  
}
export default UserProvider;