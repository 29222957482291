import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyByr6Cldd_wwqjrOn6DEhwwcitUnC53jXQ",
    authDomain: "spark-volunteer-portal.firebaseapp.com",
    projectId: "spark-volunteer-portal",
    storageBucket: "spark-volunteer-portal.appspot.com",
    messagingSenderId: "1054555100695",
    appId: "1:1054555100695:web:24d93eb6e0031357e1ee52"
};

try {

  firebase.initializeApp(firebaseConfig);

} 

catch(err){
  if (!/already exists/.test(err.message)) {
    console.error('Firebase initialization error', err.stack)}

}

export const auth = firebase.auth();

export const firestore = firebase.firestore();

// Google Sign In
const provider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
    auth.signInWithPopup(provider).then((result) => {
      /** @type {firebase.auth.OAuthCredential} */
      const credential = result.credential;
  
      // This gives you a Google Access Token. You can use it to access the Google API.
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      generateGoogleUserDocument(user);
     
    }).catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });
};

// Google Sign In User Doc Creation
const generateGoogleUserDocument = async (user, additionalData) => {
  if (!user) return;
  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    const { email, displayName } = user;
    try {
      await userRef.set({
        displayName,
        email,
        phone: "",
        country: "",
        address: "",
        city: "",
        province: "",
        postal: "",
        consentPicture: true,
        accountType: "Volunteer",
        trainingStep: 0,
        volunteer:[],
        ...additionalData
      });
    } catch (error) {
      console.error("Error creating user document", error);
    }
  }
  return getUserDocument(user.uid);
};



//Email Sign In
export const generateUserDocument = async (user, additionalData) => {
    if (!user) return;
    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
      const { email, displayName, phone } = user;
      try {
        await userRef.set({
          displayName,
          email,
          phone,
          country: "",
          address: "",
          city: "",
          province: "",
          postal: "",
          consentPicture: true,
          accountType: "Volunteer",
          trainingStep: 0,
          volunteer:[],
          ...additionalData
        });
      } catch (error) {
        console.error("Error creating user document", error);
      }
    }
    return getUserDocument(user.uid);
  };

  const getUserDocument = async uid => {
    if (!uid) return null;
    try {
      const userDocument = await firestore.doc(`users/${uid}`).get();
      return {
        uid,
        ...userDocument.data()
      };
    } catch (error) {
      console.error("Error fetching user", error);
    }
  };